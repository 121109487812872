<template>
  <b-card>
    <h1>Summations</h1>
    <vue-apex-charts :options="options" :series="series" type="bar" />
    <mixn ref="mixin" />
  </b-card>
</template>

<script>
import mixn from '@/components/mixn.vue'
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    mixn,
  },
  props: {
    sumData: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'line',
        },
        noData: {
          text: 'Search to fetch the data!',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
          },
        },
        stroke: {
          show: true,
          width: 2,
          curve: 'smooth',
        },
        dataLabels: {
          offsetY: -25,
          style: {
            colors: ['#333'],
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
            columnWidth: '50%', // Ajusta este valor para controlar el ancho de las barras
          },
        },
        xaxis: {
          type: 'category',
          tickPlacement: 'on',
        },
        markers: {
          size: 4,
        },
        labels: [],
      },
      dollars: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      }),
    }
  },
  watch: {
    sumData(newData) {
      this.setData()
    },
  },
  methods: {
    setData() {
      const newSeries = [
        {
          name: 'Suma de INS-NC',
          type: 'column',
          data: [],
        },
        {
          name: 'Suma de INS-NP',
          type: 'column',
          data: [],
        },
        {
          name: 'Suma de F/U',
          type: 'column',
          data: [],
        },
        {
          name: 'Suma de RS',
          type: 'column',
          data: [],
        },
      ]

      this.sumData.forEach(({ income, month, year, facilities }) => {
        const date = `${month}-${year}`
        let np = 0,
          nc = 0,
          fu = 0,
          pi = 0

        facilities.forEach(({ total, ...res }) => {
          console.log('🚀 ~ facilities.forEach ~ res:', res)
          np += res['INS-NC']
          nc += res['INS-NP']
          fu += res['F/U']
          pi += res['R/S']
        })

        newSeries[0].data.push({ x: `${date} - ${this.dollars.format(income)}`, y: nc })
        newSeries[1].data.push({ x: `${date} - ${this.dollars.format(income)}`, y: np })
        newSeries[2].data.push({ x: `${date} - ${this.dollars.format(income)}`, y: fu })
        newSeries[3].data.push({ x: `${date} - ${this.dollars.format(income)}`, y: pi })
      })

      this.series = newSeries
      this.$nextTick(() => {
        this.options = {
          ...this.options,
          colors: ['#cadbbb', '#fcc599', '#fbd5cc', '#FEB019'],
        }
      })
    },
  },
}
</script>
